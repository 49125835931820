import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../components/NavBar";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import MobileNavBar from "../components/MobileNavbar";
function Layout() {
  return (
    <div className="layout">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="content">
        <Outlet />
      </div>
      <div className="mobile">
        <MobileNavBar/>
      </div>
    </div>
  );
}

function RequireAuth() {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) return <Navigate to="/login" />;
  else {
    return (
      <div className="layout">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    );
  }
}

export { Layout, RequireAuth };